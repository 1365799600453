.Skills {
  font-size: 2rem;
  width: 80%;
  margin: 0 auto;
}

 ul {
  padding-left: 10px;
}

li {
  margin-left: 60px;
}

.Technical {
  width: 100%;
}

.Technical ul {
  padding-left: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Technical ul li {
  width: 250px;
  margin-bottom: 6px
}
