.App {
  width: 100%;
  min-height: 100vh;
  background-image: url("../src/Images/green.jpg");
  background-size: cover;
  background-position: center;
}

h3,
h5 {
  margin: 0;
}


@media screen and (max-width: 1024px) {
  .App {
    background-image: url("../src/Images/green.jpg");
    background-size: 100% 100%;
  }
}