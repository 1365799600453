.AboutMe {
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-radius: 10px;
}
.AboutMe h2 {
  font-size: 2.4rem;
  margin: 10px 0;
}

.AboutMe  img {
  display: none;
  width: 260px;
  height: 260px;
  border-radius: 100%;
}

.AboutMe div {
  width: 76%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.AboutMe p {
  font-size: 2.2rem;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .AboutMe  img {
    width: 220px;
    height: 220px;
  }
  .AboutMe p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .AboutMe {
    width: 80%;
    flex-direction: column;
    justify-content: center;
  }
  .AboutMe  img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    /* align-self: flex-start; */
  }

  .AboutMe div {
    width: 100%;
  }
  .AboutMe p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 800px) {
  .AboutMe {
    width: 94%;
  }

  .AboutMe h2 {
    font-size: 2rem;
  }

  .AboutMe p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 500px) {
  .AboutMe {
    justify-content: end;
    min-height: 86vh;
  }
  .AboutMe  img {
    display: flex;
    border-radius: 100%;
    /* align-self: flex-start; */
    margin-right: 40px;
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 375px) {
  .AboutMe  img {
    width: 150px;
    height: 150px;
  }

  .AboutMe p {
    font-size: 1.5rem;
  }
}
