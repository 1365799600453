.Projects {
  min-height: 80vh;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 2.2rem;
}

.Projects a {
  text-decoration: none;
  color: black;
}

.MovieKnight,
.Boo {
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(245, 245, 220, 0.4);
}

.MovieKnight div,
.Boo div {
  width: 80%;
}

.MovieKnight .img-link,
.Boo .img-link {
  width: 18%;
}

.MovieKnight img {
  width: 100%;
  height: 300px;
}

.Boo img {
  width: 100%;
}
.MovieKnight .project-details a,
.Boo .project-details a {
  margin-left: 10px;
  font-weight: bold;
  font-size: 2.4rem;
}

.MovieKnight li,
.Boo li {
  margin-left: 20px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .MovieKnight,
  .Boo {
    width: 100%;
    background-color: transparent;
  }

  .MovieKnight .project-details,
  .Boo .project-details {
    width: 80%;
  }
  .MovieKnight .project-details div,
  .Boo .project-details div {
    width: 100%;
  }

  .MovieKnight .img-link,
  .Boo .img-link {
    width: 24%;
  }
}

@media screen and (max-width: 900px) {
  .MovieKnight,
  .Boo {
    width: 100%;
    background-color: transparent;
    justify-content: space-between;
  }

  .MovieKnight .project-details,
  .Boo .project-details {
    width: 70%;
  }
  .MovieKnight .project-details div,
  .Boo .project-details div {
    width: 100%;
  }

  .MovieKnight .img-link,
  .Boo .img-link {
    width: 24%;
  }

  .MovieKnight li,
  .Boo li {
    width: 70%;
  }
}

@media screen and (max-width: 750px) {
  .MovieKnight,
  .Boo {
    width: 100%;
    background-color: transparent;
    justify-content: space-between;
  }

  .MovieKnight .project-details,
  .Boo .project-details {
    width: 68%;
  }
  .MovieKnight .project-details div,
  .Boo .project-details div {
    width: 100%;
  }

  .MovieKnight .img-link,
  .Boo .img-link {
    width: 30%;
  }

  .MovieKnight li,
  .Boo li {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .Projects {
    margin-top: 20px;
    font-size: 2rem;
  }
  .MovieKnight,
  .Boo {
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 40px;
  }

  .Projects .Hack-or-Snooze {
    border: none;
  }
  .MovieKnight,
  .Boo {
    flex-direction: column;
    width: 100%;
    background-color: transparent;
  }

  .MovieKnight .img-link,
  .Boo .img-link {
    display: flex;
    width: 40%;
    margin: 10px 0;
  }

  .MovieKnight .project-details,
  .Boo .project-details {
    width: 90%;
  }

  .MovieKnight .project-details a,
  .Boo .project-details a {
    font-size: 2.2rem;
  }

  .MovieKnight ul,
  .Boo ul {
    margin-top: 2px;
    width: 100%;
  }

  .MovieKnight li,
  .Boo li {
    width: 100%;
    margin: 6px 0;
  }
}


@media screen and (max-width: 330px) {

  .MovieKnight .img-link,
  .Boo .img-link {
    width: 50%;
  }
  .MovieKnight, .Boo{
    padding: 6px;
  }


}
