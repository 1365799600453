.HomePage {
  height: 60vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: "Handlee", cursive;
}

h4 {
  margin: 20px 0 20px 160px;
  opacity: 0;
  animation: forwards fadeIn 3s 1s;
}

h3 {
  opacity: 0;
  animation: forwards fadeIn 3s;
}

.HomePage a {
  opacity: 0;
  font-size: 1.8rem;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #70af6a;
  margin-left: 340px;
  border-radius: 10px;
  color: black;
  animation: forwards fadeIn 3s 2s;
}

@media screen and (max-width: 1024px) {
  h4 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 2.6rem;
  }

  .HomePage a {
    font-size: 1.8rem;
    height: 46px;
    margin-left: 260px;
  }
}

@media screen and (max-width: 800px) {
  .HomePage {
    width: 70%;
  }
}

@media screen and (max-width: 550px) {
  .HomePage {
    width: 80%;
  }

  h3 {
    margin: 10px 0;
    font-size: 2rem;
  }
  h4 {
    margin: 10px 0;
    font-size: 1.8rem;
  }

  .HomePage a {
    margin: 10px 0;
    font-size: 1.6rem;
    height: 40px;
    width: 120px;
  }
}

@media screen and (max-width: 375px) {
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.6rem;
  }

  .HomePage a {
    font-size: 1.2rem;
    height: 34px;
    width: 130px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
