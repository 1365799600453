html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: 'Handlee', cursive;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
