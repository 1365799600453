.NavBar {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.NavBar a {
  width: 160px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 1.7rem;
  cursor: pointer;
  text-decoration: none;
}

.NavBar a:hover {
  color: #024f04;
}

.NavBar .active {
  color: #024f04;
}

@media screen and (max-width: 1024px) {
  .NavBar a {
    width: 120px;
  }
}

@media screen and (max-width: 800px) {
  .NavBar a {
    width: 100px;
  }
}

@media screen and (max-width: 550px) {
  .NavBar a {
    width: 70px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 375px) {
  .NavBar a {
    width: 60px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 330px) {
  .NavBar a {
    width: 50px;
  }
}
