.ContactMe {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  width: 100%;
}

.ContactMe a img {
  width: 40px;
  height: 40px;
}

.ContactMe .email-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
}

.ContactMe .links,
.ContactMe .email-contact {
  width: 50%;
}

.ContactMe .email-contact h5 {
  margin-right: 10px;
}

.ContactMe .links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactMe .links div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactMe .links div a {
  margin-left: 10px;
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .ContactMe .email-contact {
    font-size: 2rem;
  }
  .ContactMe .links a {
    font-size: 1.6rem;
  }

  .ContactMe a img {
    width: 36px;
  }
}
